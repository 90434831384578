export const FirebaseConfig = {
	"projectId": "llrides-app",
	"appId": "1:86821886432:web:71d8b80fe3e3313b6f7385",
	"databaseURL": "https://llrides-app-default-rtdb.firebaseio.com",
	"storageBucket": "llrides-app.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyA7DnlXQ1qN7kv1kKLB97RUTm1ellsJDqM",
	"authDomain": "llrides-app.firebaseapp.com",
	"messagingSenderId": "86821886432",
	"measurementId": "G-1Y13N5TZ5L"
};